import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import { useEffect } from "react";
import { usePosthog } from "./hooks/posthog";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <h1>{error.status ? error.status : "Unknown Error"}</h1>;
};

export default function App() {
   const location = useLocation()
   // Hydration issues if we don't explicitly initialize it in a useeffect
    const postHogClient = usePosthog();

    useEffect(() => {
      postHogClient?.capture("$pageview")
    }, [postHogClient, location])


  return <Outlet />;
}
